<template>
  <nav class="nav-footer">
    <FooterLinkNav
      v-for="link in links"
      :key="link.label"
      :label="link.label"
      :to="link.to"
      class="link nav-footer__link"
    />
  </nav>
</template>

<script>
import FooterLinkNav from "@/components/Footer/FooterLinkNav.vue";

export default {
  components: { FooterLinkNav },

  props: {
    links: Array,
  },
};
</script>

<style scoped>
.nav-footer {
  width: 100%;

  display: flex;
  flex-flow: row;
  justify-content: center;
  column-gap: 30px;
}
</style>
