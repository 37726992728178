<template>
  <footer class="footer">
    <FooterLinkNavGroup :links="links" />
    <span class="copyright">lenlo.pl © {{ currentYear }}</span>
  </footer>
</template>

<script>
import FooterLinkNavGroup from "@/components/Footer/FooterLinkNavGroup.vue";
import useNavLinks from "@/composables/useNavLinks";

export default {
  components: {
    FooterLinkNavGroup,
  },

  setup() {
    const { navLinks } = useNavLinks();

    return {
      links: navLinks.desktopFooter,
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.footer {
  padding: 150px 0 30px;
  margin: 0 auto;
  width: var(--layout-default--width);
  display: flex;
  flex-flow: column;
  row-gap: 40px;
  align-items: center;
}
.footer .copyright {
  color: #8b93b4;
}
</style>
