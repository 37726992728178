<template>
  <ButtonLink :to="to" :label="label" class="link nav-footer__link" />
</template>

<script>
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";

export default {
  components: { ButtonLink },

  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    label: String,
  },
};
</script>

<style scoped>
.link.nav-footer__link {
  font-weight: 300;
}
.nav-footer__link.router-link-active {
  font-weight: 500;
  text-decoration: var(--button-link-hover-text-decoration);
}
</style>
